<template>
    <div>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;margin: 0;padding: 0;line-height: 39px;">店铺绑定</p>
        <van-cell-group>
            <van-field v-model="form.head_img" label="店铺头图" label-width="80">
                <template slot="input" slot-scope="props">
                    <div style="display: flex;">
                        <div v-if="head_img" class="van-image van-uploader__preview-image" style="box-sizing: border-box;margin-right: 5px;">
                            <van-image :src="head_img" fit="fill" style="display: block;height: 80px;width: 80px;" @click="showDemo([head_img])"/>
                        </div>
                        <div class="van-uploader__upload" @click="chooseHeadImg">
                            <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                            <div class="text" style="color: #646566;font-size: 12px;text-align: center;">点击上传</div>
                        </div>
                    </div>
                </template>
                <template #right-icon>
                    <span style="color: red;text-decoration: underline;" @click="help(1)">示例</span>
                </template>
            </van-field>
            <van-field v-model="form.shop_name" label="店铺名称" type="textarea" autosize placeholder="店铺名称必须与大众点评一致，分店名称需填写并加括号" />
            <van-field v-model="form.key_word" label="搜索关键词" placeholder="设置方法请查看右侧示例">
                <template #right-icon>
                    <span style="color: red;text-decoration: underline;" @click="help(2)">示例</span>
                </template>
            </van-field>
            <van-field v-model="form.link_url" label="店铺链接" type="textarea" autosize placeholder="大众点评的店铺链接，请严格按照右侧问号提示图填写店铺链接">
                <template #right-icon>
                    <span style="color: red;text-decoration: underline;" @click="help(3)">示例</span>
                </template>
            </van-field>
            <van-field v-model="form.type_name" label="店铺类型" placeholder="请选择" is-link readonly @click="shopSelectPop" />
            <van-field v-model="form.address_name" label="店铺地址" placeholder="请选择" is-link readonly @click="areaSelect = true"/>
            <van-field v-model="form.address_detail" label="详细地址" placeholder="输入详细地址" />
            <van-field v-model="form.contact_man" label="联系人" placeholder="请输入联系人姓名" />
            <van-field v-model="form.contact_phone" type="number" label="联系电话" placeholder="请输入联系电话" />
        </van-cell-group>
        <div style="width: 100%;padding:16px;box-sizing: border-box;">
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" :loading="loading" block @click="submit">确定</van-button>
        </div>
        <van-popup v-model="typeSelect" position="bottom">
            <van-picker show-toolbar :columns="types" title="选择店铺类型" @cancel="typeSelect = false" @confirm="onConfirm" />
        </van-popup>
        <van-popup v-model="areaSelect" position="bottom" >
            <van-area title="地区选择" :area-list="areaList" :value="district" @cancel="areaSelect = false" @confirm="onAreaConfirm" />
        </van-popup>
    </div>
</template>

<script>
    import {Toast,Notify,Dialog,ImagePreview} from 'vant';
    import area from "../../utils/area";
    import wx from "weixin-js-sdk";
    export default {
        name: "ShopBind",
        data(){
            return{
                form:{
                    shop_type:'',
                    type_name:'',
                    shop_name:'',
                    contact_man:'',
                    contact_phone:'',
                    address:[],
                    address_name:'',
                    address_detail:'',
                    platform:'',
                    link_url:'',
                    head_img:'',
                    key_word:'',
                },
                district:'110101',
                areaList:area,
                typeSelect:false,
                areaSelect:false,
                types:[],
                head_img:'',
                loading:false,
            }
        },
        mounted(){
            // 店铺类型
            this.post('/api/common/enums',{type:'laifei_shop_types'}).then(result=>{
                if(result.code === 0){
                    this.types = result.data;
                }
            });
            if(this.$route.query.shop){
                let shop = this.$route.query.shop;
                this.form.shop_type = shop.shop_type;
                this.form.type_name = shop.type_name;
                this.form.shop_name = shop.shop_name;
                this.form.contact_man = shop.contact_man;
                this.form.contact_phone = shop.contact_phone;
                this.form.address = [shop.province, shop.city, shop.district];
                this.form.address_name = shop.short_name;
                this.form.address_detail = shop.address;
                this.form.platform = shop.platform;
                this.form.link_url = shop.link_url;
                this.form.shop_id = shop.shop_id;
                this.form.key_word = shop.key_word;
                // 省市区处理
                this.head_img = shop.head_img;
                this.district = shop.district.toString();
            }
        },
        methods:{
            shopSelectPop(){
                if(this.form.shop_id){
                    this.$notify('店铺类型不允许修改');
                } else {
                    this.typeSelect = true;
                }
            },
            showDemo(img,startPosition = 0) {
                ImagePreview({
                    images: img,
                    startPosition:startPosition,
                    closeable: true,
                    closeOnPopstate: true,
                });
            },
            chooseHeadImg() {
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.form.head_img = res.serverId;
                                // 预览图片
                                if (/(Android)/i.test(navigator.userAgent)) {
                                    that.head_img = result.localIds[0];
                                } else {
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            that.head_img = res.localData;
                                        }
                                    });
                                }
                            },
                            fail: function (res) {
                                Toast('图片选择失败');
                                console.log(res);
                            },
                        });
                    },
                    fail: function (res) {
                        that.post('/api/common/log', {chooseImageError: res}).then(result => {
                            Toast('图片选择失败');
                            console.log(result);
                        });
                    },
                });
            },
            help(index = 0) {
                let url = '';
                if(index === 1) {
                    url = 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/head_img.png';
                } else if (index === 2) {
                    url =  'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/key_word.png';
                } else {
                    url =  'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/shop_link.jpg';
                }
                ImagePreview({
                    images: [url],
                    closeable: true,
                    closeOnPopstate: true,
                });
            },
            onConfirm(value, index) {
                if(value.value === 9){
                    Notify('新建店铺暂时不允许选择热门类型');
                    return;
                }
                this.form.shop_type = value.value;
                this.form.type_name = value.text;
                this.typeSelect = false;
            },
            onAreaConfirm(columnsNum,index){
                this.form.address = [columnsNum[0].code,columnsNum[1].code,columnsNum[2].code]
                this.form.address_name = columnsNum[0].name + '-' + columnsNum[1].name + '-' + columnsNum[2].name ;
                this.areaSelect = false;
            },
            submit(){
                let that = this;
                let form = this.form;
                if(!form.head_img && !this.head_img){
                    Notify('请上传店铺头图');
                    return;
                }
                if(!form.shop_name){
                    Notify('请输入店铺名称');
                    return;
                }
                if(!form.key_word){
                    Notify('请填写关键词');
                    return;
                }
                if(!form.link_url){
                    Notify('请填写店铺链接');
                    return;
                }
                if(!form.shop_type){
                    Notify('请选择店铺类型');
                    return;
                }
                if(!form.address.length){
                    Notify('请选择店铺地址');
                    return;
                }
                if(!form.address_detail){
                    Notify('请输入详细地址');
                    return;
                }
                if(!form.contact_man){
                    Notify('请输入联系人');
                    return;
                }
                if(!form.contact_phone){
                    Notify('请输入联系电话');
                    return;
                }
                Dialog.confirm({
                    title: '请确认',
                    message: '您已严格按照大众点评的信息填写店铺名称和链接，否则达人将无法接单您发布的任务',
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                }).then(() => {
                    this.loading = true;
                    this.post('/api/client/shop/bind',this.form).then(result=>{
                        this.loading = false;
                        if(result.code === 0){
                            Dialog.alert({
                                title: '提示',
                                message: result.msg
                            }).then(() => {
                                that.$router.back();
                            });
                        } else {
                            Toast(result.msg);
                        }
                    })
                });
            }
        }
    }
</script>

<style scoped>
    .field_label p{
        margin: 0;
    }
</style>